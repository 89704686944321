<template>
  <div class="payButton">
    <el-form label-position="top" v-if="!field.is_global_variable">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <title-and-description :field="field" />
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="form-group">
            <el-form-item label="Payment through *">
              <el-select
                v-model="field.validations.paymentSystems"
                multiple
                placeholder="Select Methods"
                @change="updateRedirectUrl"
              >
                <el-option
                  v-for="item in getIntegratedList.cred_details"
                  :key="item._id"
                  :label="item.accountName"
                  :value="item._id"
                >
                  <span style="float: left">{{ item.accountName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.pgType
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="form-group">
            <el-form-item label="Modes *">
              <el-select
                v-model="field.validations.payModes"
                multiple
                placeholder="Select Modes"
              >
                <el-option
                  v-for="item in paymentModes"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                  <span style="float: left">{{ item }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="form-group">
            <el-form-item label="Fields *">
              <el-select
                v-model="field.validations.entityVariable_info"
                placeholder="Select Charge Field"
                @change="getCurrencyType"
                filterable
              >
                <el-option
                  v-for="(item, index) in filteredFields"
                  :key="'AmountFiled_' + index"
                  :label="item.field_name"
                  :value="item.field_new_key"
                >
                  <span style="float: left">{{ item.field_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.template_name
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item class="currency" label=" Currency Types">
            <el-select
              v-model="field.validations.currency"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in CurrencyTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: left">{{ item.value }}</span>
                <span
                  style="
                    float: right;
                    color: var(--el-text-color-secondary);
                    font-size: 13px;
                  "
                  >{{ item.name }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="form-group">
            <el-form-item label="Redirection URL">
              <el-input
                placeholder="Default Redirection URL"
                v-model="field.validations.defaultRedirectUrl"
                style="display: none"
              ></el-input>
              <el-input
                placeholder="Redirection URL"
                v-model="field.validations.redirectUrl"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="form-group">
            <el-form-item label="Expiration Field">
              <el-select
                v-model="field.validations.expiry"
                placeholder="Select Expiry Field"
                @change="getCurrencyType"
                filterable
              >
                <el-option
                  v-for="(item, index) in filteredDateFields"
                  :key="'AmountFiled_' + index"
                  :label="item.field_name"
                  :value="item.field_new_key"
                >
                  <span style="float: left">{{ item.field_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.template_name
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="PB_5">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <label>Transaction Types</label>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <el-radio-group
              size="mini"
              v-model="field.validations.transactionType"
            >
              <el-radio-button label="NONE">Default</el-radio-button>
              <el-radio-button label="AUTO_PAY">Auto Pay</el-radio-button>
              <el-radio-button label="RECURRING">Recurring</el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="10" class="PB_5">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <label>Do you need to enable Partial Payment?</label>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-switch
            v-model="field.validations.partialPayment"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>

          <!-- <div class="form-group">
            <el-radio v-model="field.validations.partialPayment" :label="true">Yes</el-radio>
            <el-radio v-model="field.validations.partialPayment" :label="false">No</el-radio>
          </div> -->
        </el-col>
      </el-row>
      <hr />
      <label>Advance Settings</label>
      <hr />
      <el-row :gutter="10" class="PB_5">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <label>Do you need to include Child Tables with grouping ?</label>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-switch
            v-model="field.validations.isChildTable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
          v-if="field.validations && field.validations.isChildTable"
        >
          <div class="form-group">
            <el-form-item>
              <el-select
                v-model="field.validations.childGroupField"
                placeholder="Select Group Field"
                filterable
              >
                <el-option
                  v-for="(item, index) in getAllFields"
                  :key="'GroupField_' + index"
                  :label="item.label"
                  :value="item.key"
                >
                  <span style="float: left">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="PB_5">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <label>Do you need to include split transaction?</label>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-switch
            v-model="field.validations.splitTransaction"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
          v-if="field.validations && field.validations.splitTransaction"
        >
          <div class="form-group">
            <el-form-item>
              <el-select
                v-model="field.validations.splitGroupField"
                placeholder="Select Entity"
                filterable
              >
                <el-option
                  v-for="(item, index) in entitiesData"
                  :key="'entity' + index"
                  :label="item.name"
                  :value="item._id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ item.entity_type }}
                    <!-- <i
                      class="el-icon-star-on"
                      v-if="item.entity_type === 'BUSINESS'"
                    ></i>
                    <i
                      class="el-icon-user"
                      v-if="item.entity_type === 'INDIVIDUAL'"
                    ></i>

                    <i
                      class="el-icon-magnet"
                      v-if="item.entity_type === 'GENERAL'"
                    ></i> -->
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="PB_5">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="form-group">
            <label>Do you need to calculate previous transactions?</label>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-switch
            v-model="field.validations.transactionsCal"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <!-- <div class="form-group">
            <el-radio v-model="field.validations.transactionsCal" :label="true">Yes</el-radio>
            <el-radio v-model="field.validations.transactionsCal" :label="false">No</el-radio>
          </div> -->
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import templateBuilderHelper from "../../../mixins/templateBuilderHelper";
export default {
  name: "templates-formComponents-Payment",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
  },
  mixins: [templateBuilderHelper],
  props: [
    "field",
    "templatesData",
    "selfTemplate",
    "selfTemplateId",
    "isFromDocument",
    "fieldsData",
  ],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("paymentGateway", [
      "getPlansData",
      "getActivePaymentSystems",
      "getPaymentGatewayInfo",
      "getUpdateSystemInfo",
      "getIntegratedList",
    ]),

    ...mapGetters("entities", ["getEntityById", "getEntityDataByEntityId"]),
    ...mapGetters("auth", ["getActiveWorkspace"]),

    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
    filteredFields() {
      return this.getFields.filter((item) =>
        item.field_name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
    filteredDateFields() {
      return this.getDateFields.filter((item) =>
        item.field_name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
    getDocumentFields() {
      let elements = [];
      this.templatesData.forEach((el) => {
        if (
          this.field.key != el.key &&
          (el.type == "NUMBER" ||
            el.type == "DATE" ||
            el.type == "CURRENCY" ||
            el.type == "DATE" ||
            el.type == "LIST" ||
            el.type == "TIME" ||
            el.type == "DATE_TIME_RANGE" ||
            el.type == "FORMULA" ||
            el.type == "AGGREGATE_FUNCTION")
        ) {
          let obj = {
            template_id: null,
            template_name: "self",
            field_id: el._id,
            field_name: el.label,
            label: el.label,
            key: el.key,
            u_key: el.key,
            input_type: el.type,
          };
          if (el.type == "CURRENCY") {
            obj = {
              ...obj,
              ...{
                currency_type:
                  el.validations && el.validations.currency
                    ? el.validations.currency
                    : "USD",
              },
            };
          }
          if (el.type == "DATE") {
            obj = { ...obj, ...{ date: el.label } };
          }
          if (el.type == "FORMULA") {
            obj = {
              ...obj,
              ...{
                formula: el.formula,
                result_type: el.result_type ? el.result_type : "NUMBER",
              },
            };
          }
          elements.push(obj);
        } else if (this.field.key != el.key && el.input_type == "DATA_TABLE") {
          if (this.el.data_table_columns && this.el.data_table_columns.length) {
            let nestedElements = this.getFormulaAllowedFieldsForDataTable(
              this.el.data_table_columns,
              this.el.key
            );
            elements = [...elements, ...nestedElements];
          }
        }
      });
      return elements;
    },

    getPaymentFields() {
      return (selfTemplate, key) => {
        let elements = [];
        for (let index = 0; index < selfTemplate.length; index++) {
          if (selfTemplate[index].input_type === "NUMBER") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
            });
          }

          if (selfTemplate[index].input_type === "CURRENCY") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
            });
          }

          if (selfTemplate[index].input_type === "ENTITY_VARIABLE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              currency_type: selfTemplate[index].validations.currency,
            });
          }

          if (selfTemplate[index].input_type === "FORMULA") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              formula: selfTemplate[index].formula,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              currency_type: selfTemplate[index].validations.currency,
            });
          }

          if (
            selfTemplate[index].input_type === "AGGREGATE_FUNCTION" &&
            key != selfTemplate[index].key
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              currency_type: selfTemplate[index].validations.currency,
            });
          }
        }
        return elements;
      };
    },
    getPaymentDateFields() {
      return (selfTemplate) => {
        let elements = [];
        for (let index = 0; index < selfTemplate.length; index++) {
          if (selfTemplate[index].input_type === "DATE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
            });
          }
          if (selfTemplate[index].input_type === "FORMULA") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              formula: selfTemplate[index].formula,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              field_new_key:
                "Self" +
                "#" +
                selfTemplate[index]._id +
                "#" +
                selfTemplate[index].key,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              currency_type: selfTemplate[index].validations.currency,
            });
          }
        }
        return elements;
      };
    },
    getSelfTemplateFields() {
      return (selfTemplate, key) => {
        let elements = [];
        for (let index = 0; index < selfTemplate.length; index++) {
          console.log(
            "Field label",
            selfTemplate[index].label,
            selfTemplate[index]
          );
          if (selfTemplate[index].input_type === "NUMBER") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "CURRENCY") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "DATE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "DATE_TIME") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "TIME") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "LIST") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "DATE_TIME_RANGE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (
            selfTemplate[index].input_type === "ENTITY_VARIABLE" &&
            [
              "NUMBER",
              "CURRENCY",
              "DATE",
              "TIME",
              "LIST",
              "DATE_TIME_RANGE",
              "AGGREGATE_FUNCTION",
            ].indexOf(selfTemplate[index].inputType) != -1
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (
            selfTemplate[index].input_type === "PAYMENT_VARIABLE" &&
            [
              "NUMBER",
              "CURRENCY",
              "DATE",
              "TIME",
              "DATE_TIME",
              "DATE_TIME_RANGE",
            ].indexOf(selfTemplate[index].inputType) != -1
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          // if (
          //   selfTemplate[index].input_type === "SELECT" &&
          //   selfTemplate[index].is_list
          // ) {
          //   elements.push({
          //     template_name: "Self",
          //     field_name: selfTemplate[index].label,
          //     key: selfTemplate[index].key,
          //     u_key: "self" + selfTemplate[index].key,
          //     input_type: selfTemplate[index].input_type,
          //     ...(selfTemplate[index].data_table_key && {
          //       data_table_key: selfTemplate[index].data_table_key,
          //     }),
          //   });
          // }

          if (selfTemplate[index].input_type === "FORMULA") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              formula: selfTemplate[index].formula,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (
            selfTemplate[index].input_type === "AGGREGATE_FUNCTION" &&
            key != selfTemplate[index].key
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
        }
        return elements;
      };
    },
    getAllFields() {
      let elements = [];

      this.fieldsData.map(async (field) => {
        elements.push(field);
      });
      // for (let i = 0; i < this.templatesData.length; i++) {
      //   console.log("Templates",this.templatesData)
      //   let fields =
      //     this.templatesData[i]._id != this.selfTemplateId
      //       ? this.templatesData[i].sections[0].fields
      //       : [];
      //       console.log("Fields",fields)
      //   for (let j = 0; j < fields.length; j++) {
      //     //if (fields[j].input_type === "NUMBER") {
      //       elements.push({
      //         template_id: this.templatesData[i]._id,
      //         template_name: this.templatesData[i].name,
      //         field_id: fields[j]._id,
      //         field_name: fields[j].label,
      //         key: fields[j].key,
      //         u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
      //         input_type: fields[j].input_type,
      //       });
      //     // }

      //   }
      // }

      // let selfTemplateFields = this.getSelfTemplateFields(
      //   this.selfTemplate,
      //   this.field.key
      // );

      // if (this.field.data_table_field_index > -1) {
      //   let dataTableFieldsElements = [];
      //   this.selfTemplate.forEach((e) => {
      //     if (e.input_type == "DATA_TABLE") {
      //       dataTableFieldsElements = [
      //         ...dataTableFieldsElements,
      //         ...e.data_table_columns.map((fl) => {
      //           fl.data_table_key = e.key;
      //           return fl;
      //         }),
      //       ];
      //     }
      //   });
      //   let selfTemplateDataTableFields = this.getSelfTemplateFields(
      //     dataTableFieldsElements,
      //     this.field.key
      //   );

      //   elements = [...selfTemplateDataTableFields, ...elements];
      // }

      //elements = [...selfTemplateFields, ...elements];
      return elements;
    },

    getFields() {
      let elements = [];
      for (let i = 0; i < this.templatesData.length; i++) {
        let fields =
          this.templatesData[i]._id != this.selfTemplateId
            ? this.templatesData[i].sections[0].fields
            : [];
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].input_type === "NUMBER") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
              input_type: fields[j].input_type,
            });
          }
          if (fields[j].input_type === "CURRENCY") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
              input_type: fields[j].input_type,
              currency_type: fields[j].validations.currency,
            });
          }
          if (fields[j].input_type === "FORMULA") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
              input_type: fields[j].input_type,
              result_type: fields[j].result_type,
            });
          }

          if (fields[j].input_type === "ENTITY_VARIABLE") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].inputType,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
            });
          }
        }
      }

      let selfTemplateFields = this.getPaymentFields(
        this.selfTemplate,
        this.field.key
      );

      if (this.field.data_table_field_index > -1) {
        let dataTableFields = this.selfTemplate.filter(
          (e) => e.input_type == "DATA_TABLE"
        );

        let dataTableFieldsElements = dataTableFields.map(
          (e) => e.data_table_columns
        );
        dataTableFieldsElements = [].concat.apply([], dataTableFieldsElements);

        let selfTemplateDataTableFields = this.getPaymentFields(
          dataTableFieldsElements,
          this.field.key
        );

        elements = [...selfTemplateDataTableFields, ...elements];
      }

      elements = [...selfTemplateFields, ...elements];

      return elements;
    },
    getDateFields() {
      let elements = [];
      for (let i = 0; i < this.templatesData.length; i++) {
        let fields =
          this.templatesData[i]._id != this.selfTemplateId
            ? this.templatesData[i].sections[0].fields
            : [];
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].input_type === "DATE") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
              input_type: fields[j].input_type,
            });
          }
          if (fields[j].input_type === "FORMULA") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              field_new_key:
                this.templatesData[i]._id +
                "#" +
                fields[j]._id +
                "#" +
                fields[j].key,
              input_type: fields[j].input_type,
              result_type: fields[j].result_type,
            });
          }
        }
      }
      let selfTemplateFields = this.getPaymentDateFields(this.selfTemplate);

      elements = [...selfTemplateFields, ...elements];
      return elements;
    },
  },
  mounted() {
    this.getFields;
    this.field.pay_status = [
      "PAID",
      "NOT PAID",
      "PARTIALLY PAID",
      "INITIATED",
      "SUBSCRIBED",
      "NO ACTION DONE",
      "PROCESSING",
    ];
    this.field.default_value = "NO ACTION DONE";
    this.fetchGlobalVariables();
    this.getActivePaymentSystemsData();
    this.fetchEntities();
    this.updateRules = this.field.validations.updateRules;
    if (!this.field.validations.defaultRedirectUrl) {
      this.field.validations.defaultRedirectUrl =
        "/entity-payment-status-update?SessionData=";
    }
    // if(this.field.validations && this.field.validations.transactionType)
    // {
    //   //
    // }
  },
  data() {
    return {
      filterText: "",
      entityVariable: "",
      selectedEntityFields: [],
      paymentModes: ["ACH", "CREDIT CARD", "UPI", "NET BANKING"],
      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },
        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        {
          value: "EUR",
          name: "€",
        },
        {
          value: "ZAR",
          name: "R",
        },
        {
          value: "MXN",
          name: "$",
        },
        {
          value: "MYR",
          name: "RM",
        },
        {
          value: "GBP",
          name: "£",
        },
      ],
      successRule: {
        entity: "",
        field_key: "",
        action: "",
        value: "",
        entity_info: {},
        field: {},
      },
      failureRule: {
        entity: "",
        field_key: "",
        action: "",
        value: "",
        entity_info: {},
        field: {},
      },
      updateRules: {
        success: [],
        failure: [],
      },
      actionTypesList: ["CREATE", "UPDATE", "DELETE"],
      entitiesData: [],
    };
  },
  methods: {
    updateRedirectUrl() {
      if (
        this.field.validations.paymentSystems &&
        this.field.validations.paymentSystems[0]
      ) {
        this.field.validations.defaultRedirectUrl =
          "/entity-payment-status-update?SessionData=" +
          this.field.validations.paymentSystems[0];
      }
    },
    async fetchEntities() {
      try {
        this.entitiesData = await this.fetchAllEntities(false);
        // await this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        // });
      } catch (error) {
        console.log("fetchEntities", error);
      }
    },
    async setEntityFields(type) {
      try {
        let entityId = "";
        if (type === "SUCCESS") {
          entityId = this.successRule.entity;
          // let entity = await this.lodash.filter(this.getAllEntities.data, function (o) { return o._id === entityId })
          let entity = this.entitiesData.filter((o) => o._id === entityId);
          this.successRule.entity_info = entity[0];
        } else if (type === "FAILURE") {
          entityId = this.failureRule.entity;
          // let entity = await this.lodash.filter(this.getAllEntities.data, function (o) { return o._id === entityId })
          let entity = this.entitiesData.filter((o) => o._id === entityId);
          this.failureRule.entity_info = entity[0];
        }
        this.loading = true;
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: entityId,
        });

        this.selectedEntityFields = [];
        if (this.getEntityById && this.getEntityById.templates) {
          this.getEntityById.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
          // await this.checkRationshipsData(entityId);
        }
        this.loading = false;
      } catch (err) {
        console.log("setEntityFields", err);
      }
    },
    async getField(type) {
      if (type === "SUCCESS") {
        //
        let key = this.successRule.field_key;
        // let fieldInfo = await this.lodash.filter(this.selectedEntityFields, function (o) { return o.field_new_key === key })
        let fieldInfo = this.selectedEntityFields.filter(
          (o) => o.field_new_key === key
        );
        this.successRule.field = fieldInfo && fieldInfo[0] ? fieldInfo[0] : {};
      } else if (type === "FAILURE") {
        //
        let key = this.failureRule.field_key;
        // let fieldInfo = await this.lodash.filter(this.selectedEntityFields, function (o) { return o.field_new_key === key })
        let fieldInfo = this.selectedEntityFields.filter(
          (o) => o.field_new_key === key
        );
        this.failureRule.field = fieldInfo && fieldInfo[0] ? fieldInfo[0] : {};
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        e.field_new_key = data._id + "#" + e._id + "#" + e.key;
        return e;
      });
      return fields;
    },

    getCurrencyType() {
      if (
        this.field &&
        this.field.validations &&
        this.field.validations.entityVariable_info
      ) {
        const selectedField = this.field.validations.entityVariable_info;
        let fieldList = this.getFields;
        // let fieldInfo = this.lodash.filter(fieldList, function (o) { return o.field_new_key === selectedField })
        let fieldInfo = fieldList.filter(
          (o) => o.field_new_key === selectedField
        );
        let entityVariable = fieldInfo && fieldInfo.length ? fieldInfo[0] : {};
        this.field.validations.entityVariable = entityVariable;
        this.field.validations.currency =
          entityVariable && entityVariable.currency_type
            ? entityVariable.currency_type
            : "USD";
      }
    },
    getLabelName(item) {
      if (item.template_name) {
        return item.template_name + "/" + item.field_name;
      } else {
        return item.field_name;
      }
    },
    checkDisabled(type) {
      // , item = ""
      if (
        // type == "field" ||
        type == "number" ||
        type == "currency"
        // ||
        // type == "global_variable" ||
        // type == "date" ||
        // type == "time" ||
        // type == "list"
      ) {
        if (this.field.selected_fields.length) {
          if (this.activeClassIndex > -1) {
            let fieldData = this.field.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              // fieldData.type == "FIELD" ||
              (fieldData.type == "NUMBER" ||
                // fieldData.type == "DATE" ||
                // fieldData.type == "TIME" ||
                // fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY")
              // ||
              // fieldData.type == "GLOBAL_VARIABLE" ||
              // fieldData.type == "FORMULA"
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FIELD" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "NUMBER" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "TIME" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE_TIME_RANGE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "LIST" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "GLOBAL_VARIABLE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FORMULA" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "CURRENCY" ||
              (this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "PARENTHESES" &&
                this.field.selected_fields[
                  this.field.selected_fields.length - 1
                ].parentheses == ")")
            );
          }
        }
        return false;
      } else {
        return false;
      }
    },
    async getActivePaymentSystemsData() {
      // this.loading = true;
      await this.$store.dispatch(
        "paymentGateway/fetchInitSystemsData",
        this.getActiveWorkspace.company_id
      );
      // this.loading = false;
      if (
        this.getIntegratedList &&
        this.getIntegratedList.isDefault &&
        this.field.validations.paymentSystems &&
        !this.field.validations.paymentSystems.length
      ) {
        this.field.validations.paymentSystems = [
          this.getIntegratedList.isDefault,
        ];
      }
    },
    async fetchGlobalVariables() {
      let params = {
        get_all: true,
        input_type: this.field.input_type,
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
  },
};
</script>

<style lang="scss">
.payButton {
  max-height: 600px !important;
  overflow: scroll !important;
}

.PB_5 {
  padding: 5px;
}
</style>
